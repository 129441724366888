import './App.css';
import React, { useState, useEffect } from 'react';
import api from './request/api';
import smoothscroll from 'smoothscroll-polyfill';
window.imageHost = 'https://resource.budaofilm.cn/';

let clipboard = new window.ClipboardJS('#wechatBtn');
clipboard.on('success', function (e) {
  window.iqwerty.toast.toast('微信号已复制，请打开微信搜索添加！');
  console.info('Action:', e.action);
  console.info('Text:', e.text);
  console.info('Trigger:', e.trigger);
  e.clearSelection();
});

clipboard.on('error', function (e) {
  console.error('Action:', e.action);
  console.error('Trigger:', e.trigger);
});
const App = () => {
  const [state, setState] = useState({
    windowHeight: document.body.clientHeight - 100,
    panelWidth: document.body.clientWidth,
    pageName: 'main',
    title: '',
    subtitle: '',
    logo: '',
    videoDetail: {},
    videosList: [],
    photoDetail: {},
    photosList: [],
    contact: {},
    background_image: '',
    background_color: '',
    works: [],
    imageHeight: document.body.clientWidth / (818 / 1304),
    imageHeight1: document.body.clientWidth / (1000 / 563),
    imageHeight2: document.body.clientWidth / (1000 / 426),
    imageHeight3: document.body.clientWidth / (1000 / 426),
    imageHeight4: document.body.clientWidth / (1954 / 716),
    imageHeight5: document.body.clientWidth / (1000 / 1104),
    logo_width: 0,
    logo_height: 0,
    background_image_width: 0,
    background_image_height: 0,
  });

  const [menuVisible, setMenuVisible] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    api.main.mainConfig().then((res) => {
      state.title = res.title;
      state.subtitle = res.subtitle;
      state.works = res.works;
      state.background_color = res.background_color;
      state.background_image = res.background_image;
      state.logo = res.logo;
      state.logo_width = res.logo_width;
      state.logo_height = res.logo_height;
      state.background_image_width = res.background_image_width;
      state.background_image_height = res.background_image_height;
      document.body.style.backgroundColor = res.background_color;
      setState({ ...state });
      setLoading(false);
    });
    api.main.videosList().then((res) => {
      state.videosList = res.list;
      setState({ ...state });
    });
    api.main.contactConfig().then((res) => {
      state.contact = res;
      setState({ ...state });
    });
    api.main.photosList().then((res) => {
      state.photosList = res.list;
      setState({ ...state });
    });
    smoothscroll.polyfill();
  }, []);

  const handler = {
    handlerPageEvent(pageName, item) {
      state.pageName = pageName;
      if (pageName === 'videoWorkDetail') {
        state.videoDetail = item;
      } else if (pageName === 'photoWorkDetail') {
        state.photoDetail = item;
      }
      setState({ ...state });
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    },
    handlerShowMenu(visible) {
      setMenuVisible(visible);
    },
  };
  // const logoHeight = state.panelWidth / (state.logo_width / state.logo_height);
  const logoHeight = 100;
  return (
    <div>
      {' '}
      {state.logo && (
        <div
          className="main-header"
          style={{
            width: state.panelWidth + 'px',
            height: logoHeight,
            backgroundColor:
              state.background_color !== 'black' ? '#fff' : '#000',
          }}
        >
          <img
            alt=""
            className="main-logo"
            style={{
              height: logoHeight - 40 + 'px',
              marginLeft: '12px',
            }}
            src={window.imageHost + state.logo}
            onClick={handler.handlerPageEvent.bind(this, 'main')}
          />
          <div
            className="main-right"
            onClick={handler.handlerShowMenu.bind(this, true)}
          >
            <div className="main-line"></div>
            <div className="main-line"></div>
            <div className="main-line"></div>
          </div>
        </div>
      )}
      {menuVisible && (
        <div
          className="menu-wrapper"
          onClick={handler.handlerShowMenu.bind(this, false)}
        >
          <div className="menu-content">
            <div
              className="menu-item"
              onClick={handler.handlerPageEvent.bind(this, 'main')}
            >
              <div className="menu-text-en">Back to Home</div>
              <div className="menu-text-zh">回到首页</div>
            </div>
            <div
              className="menu-item"
              onClick={handler.handlerPageEvent.bind(this, 'videoWork')}
            >
              <div className="menu-text-en">Video creative works</div>
              <div className="menu-text-zh">视频创意作品</div>
            </div>
            <div
              className="menu-item"
              onClick={handler.handlerPageEvent.bind(this, 'photoWork')}
            >
              <div className="menu-text-en">Graphic creative works</div>
              <div className="menu-text-zh">平面创意作品</div>
            </div>
            <div
              className="menu-item"
              onClick={handler.handlerPageEvent.bind(this, 'contact')}
            >
              <div className="menu-text-en">Contact us</div>
              <div className="menu-text-zh">联系我们</div>
            </div>
          </div>
        </div>
      )}
      {loading ? null : (
        <>
          {state.pageName === 'main' ? (
            <div key="main">
              <div
                className="main-bg"
                style={{
                  top: logoHeight + 'px',
                  height:
                    state.panelWidth /
                      (state.background_image_width /
                        state.background_image_height) +
                    'px',
                }}
              >
                <img
                  alt=""
                  style={{
                    height:
                      state.panelWidth /
                        (state.background_image_width /
                          state.background_image_height) +
                      'px',
                    width: state.panelWidth + 'px',
                  }}
                  src={window.imageHost + state.background_image}
                  className="main-bg-icon"
                />
                <div className="main-bg-style"></div>
                <div className="main-box">
                  <div className="main-title">
                    <div className="main-title-1">{state.title}</div>
                  </div>
                  <div className="main-desc">{state.subtitle}</div>
                </div>
                <div className="main-panel">
                  <div
                    className="main-item"
                    onClick={handler.handlerPageEvent.bind(this, 'videoWork')}
                  >
                    <div className="main-font-1">Video creative works</div>
                    <div className="main-font-2">视频创意作品</div>
                    <div className="main-line"></div>
                  </div>
                  <div
                    className="main-item"
                    onClick={handler.handlerPageEvent.bind(this, 'photoWork')}
                  >
                    <div className="main-font-1">Graphic creative works</div>
                    <div className="main-font-2">平面创意作品 </div>
                    <div className="main-line"></div>
                  </div>
                  <div
                    className="main-item"
                    onClick={handler.handlerPageEvent.bind(this, 'contact')}
                  >
                    <div className="main-font-1">Contact us</div>
                    <div className="main-font-2">联系我们</div>
                    <div className="main-line"></div>
                  </div>
                </div>
              </div>
              <div
                className="main-list"
                style={{
                  top:
                    logoHeight +
                    state.panelWidth /
                      (state.background_image_width /
                        state.background_image_height) +
                    'px',
                }}
              >
                {state.works.map((work) => {
                  return (
                    <>
                      {work.type === 'photo' ? (
                        <div
                          key={work.id}
                          className="main-item"
                          style={{
                            width: state.panelWidth + 'px',
                            height:
                              state.panelWidth / (work.width / work.height) +
                              'px',
                          }}
                          onClick={handler.handlerPageEvent.bind(
                            this,
                            'photoWorkDetail',
                            work,
                          )}
                        >
                          <img
                            alt=""
                            className="main-image-style"
                            style={{
                              width: state.panelWidth + 'px',
                              height:
                                state.panelWidth / (work.width / work.height) +
                                'px',
                            }}
                            src={window.imageHost + work.etag}
                          />
                          <div
                            className="main-title"
                            style={{
                              width: state.panelWidth + 'px',
                              height:
                                state.panelWidth / (work.width / work.height) +
                                'px',
                            }}
                          >
                            <div className="main-title-1">{work.title}</div>
                            <div className="main-title-2">{work.subtitle}</div>
                          </div>
                        </div>
                      ) : (
                        <div
                          key={work.id}
                          className="main-item"
                          style={{
                            width: state.panelWidth + 'px',
                            height:
                              state.panelWidth / (work.width / work.height) +
                              'px',
                          }}
                          onClick={handler.handlerPageEvent.bind(
                            this,
                            'videoWorkDetail',
                            work,
                          )}
                        >
                          <img
                            alt=""
                            className="main-image-style"
                            style={{
                              width: state.panelWidth + 'px',
                              height:
                                state.panelWidth / (work.width / work.height) +
                                'px',
                            }}
                            src={
                              window.imageHost +
                              work.etag +
                              `?x-oss-process=video/snapshot,t_1000,f_jpg,w_0,h_0,m_fast,ar_auto`
                            }
                          />
                          <div
                            className="main-title"
                            style={{
                              width: state.panelWidth + 'px',
                              height:
                                state.panelWidth / (work.width / work.height) +
                                'px',
                            }}
                          >
                            <div className="main-title-1">{work.title}</div>
                            <div className="main-title-2">{work.subtitle}</div>
                          </div>
                        </div>
                      )}
                    </>
                  );
                })}
              </div>
            </div>
          ) : null}
          {state.pageName === 'videoWorkDetail' ? (
            <div key="videoWorkDetail">
              <div
                className="main-list"
                style={{
                  top: logoHeight + 'px',
                }}
              >
                <div className="main-txt">{state.videoDetail.title}</div>
                <video
                  src={window.imageHost + state.videoDetail.etag}
                  controls
                  style={{
                    marginTop: '30px',
                    width: state.panelWidth + 'px',
                    height:
                      state.panelWidth /
                        (state.videoDetail.width / state.videoDetail.height) +
                      'px',
                  }}
                />
                {state.videoDetail.items.map((item, index) => {
                  return (
                    <div
                      key={item.id}
                      className="main-item"
                      style={{
                        height:
                          state.panelWidth / (item.width / item.height) + 'px',
                        marginTop: index === 0 ? '7px' : '7px',
                      }}
                    >
                      <img
                        alt=""
                        className="main-image-style"
                        style={{
                          height:
                            state.panelWidth / (item.width / item.height) +
                            'px',
                        }}
                        src={window.imageHost + item.etag}
                      />
                    </div>
                  );
                })}
              </div>
            </div>
          ) : null}
          {state.pageName === 'photoWorkDetail' ? (
            <div key="photoWorkDetail">
              <div
                className="main-list"
                style={{
                  top: logoHeight + 'px',
                }}
              >
                <div className="main-txt">{state.photoDetail.title}</div>
                <img
                  alt=""
                  src={window.imageHost + state.photoDetail.etag}
                  style={{
                    marginTop: '30px',
                    width: state.panelWidth + 'px',
                    height:
                      state.panelWidth /
                        (state.photoDetail.width / state.photoDetail.height) +
                      'px',
                  }}
                />
                {state.photoDetail.items.map((item, index) => {
                  return (
                    <div
                      key={item.id}
                      className="main-item"
                      style={{
                        height:
                          state.panelWidth / (item.width / item.height) + 'px',
                        marginTop: index === 0 ? '7px' : '7px',
                      }}
                    >
                      <img
                        alt=""
                        className="main-image-style"
                        style={{
                          height:
                            state.panelWidth / (item.width / item.height) +
                            'px',
                        }}
                        src={window.imageHost + item.etag}
                      />
                    </div>
                  );
                })}
              </div>
            </div>
          ) : null}
          {state.pageName === 'videoWork' ? (
            <div key="videoWork">
              <div
                className="main-list"
                style={{
                  top: logoHeight + 'px',
                }}
              >
                {state.videosList.map((video) => {
                  let banner = {
                    width: video.width,
                    height: video.height,
                    etag:
                      video.etag +
                      `?x-oss-process=video/snapshot,t_1000,f_jpg,w_0,h_0,m_fast,ar_auto`,
                  };
                  if (video.banner) {
                    banner = {
                      width: video.banner_width,
                      height: video.banner_height,
                      etag: video.banner,
                    };
                  }
                  return (
                    <div
                      key={video.id}
                      className="main-item"
                      onClick={handler.handlerPageEvent.bind(
                        this,
                        'videoWorkDetail',
                        video,
                      )}
                    >
                      <img
                        alt=""
                        className="main-image-style"
                        style={{
                          height:
                            state.panelWidth / (banner.width / banner.height) +
                            'px',
                        }}
                        src={window.imageHost + banner.etag}
                      />
                      <div
                        className="main-title"
                        style={{
                          height:
                            state.panelWidth / (banner.width / banner.height) +
                            'px',
                        }}
                      >
                        <div className="main-title-1">{video.title}</div>
                        <div className="main-title-2">{video.subtitle}</div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          ) : null}
          {state.pageName === 'photoWork' ? (
            <div key="photoWork">
              <div
                className="main-list"
                style={{
                  top: logoHeight + 'px',
                }}
              >
                {state.photosList.map((photo) => {
                  return (
                    <div
                      key={photo.id}
                      className="main-item"
                      onClick={handler.handlerPageEvent.bind(
                        this,
                        'photoWorkDetail',
                        photo,
                      )}
                    >
                      <img
                        alt=""
                        className="main-image-style"
                        style={{
                          height:
                            state.panelWidth / (photo.width / photo.height) +
                            'px',
                        }}
                        src={window.imageHost + photo.etag}
                      />
                      <div
                        style={{
                          height:
                            state.panelWidth / (photo.width / photo.height) +
                            'px',
                        }}
                        className="main-title"
                      >
                        <div className="main-title-1">{photo.title}</div>
                        <div className="main-title-2">{photo.subtitle}</div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          ) : null}
          {state.pageName === 'contact' ? (
            <div
              key="contact"
              className="contact-page"
              style={{
                top: logoHeight + 'px',
              }}
            >
              {/* <div className="contact-title">
            <div className="contact-title-1">我们一直相信</div>
            <div className="contact-title-2">“懂得如何去玩</div>
            <div className="contact-title-3">才会创造更多可能”</div>
          </div> */}
              {state.contact.items.map((item) => {
                return (
                  <div
                    key={item.id}
                    className="contact-item"
                    style={{
                      height:
                        state.panelWidth / (item.width / item.height) + 'px',
                      width: state.panelWidth + 'px',
                    }}
                  >
                    <img
                      alt=""
                      className="contact-image-style"
                      style={{
                        height:
                          state.panelWidth / (item.width / item.height) + 'px',
                        width: state.panelWidth + 'px',
                      }}
                      src={window.imageHost + item.etag}
                    />
                  </div>
                );
              })}
              <div className="contact-ways">
                <a className="contact-way" href={'tel:' + state.contact.phone}>
                  <div
                    className="contact-way-icon"
                    style={{
                      backgroundImage:
                        'url(https://resource.budaofilm.cn/1679110677217-phone-fill.png)',
                    }}
                  ></div>
                  <div className="contact-way-text">拨打电话</div>
                </a>
                <div
                  className="contact-way"
                  id="wechatBtn"
                  data-clipboard-text={state.contact.wechat}
                >
                  <div
                    className="contact-way-icon"
                    style={{
                      backgroundImage:
                        'url(https://resource.budaofilm.cn/1679110677217-wechat-fill.png)',
                    }}
                  ></div>
                  <div className="contact-way-text">添加微信</div>
                </div>
                <a
                  className="contact-way"
                  href={'mailto:' + state.contact.email}
                >
                  <div
                    className="contact-way-icon"
                    style={{
                      backgroundImage:
                        'url(https://resource.budaofilm.cn/1679110677217-mail-fill.png)',
                    }}
                  ></div>
                  <div className="contact-way-text">发送电邮</div>
                </a>
              </div>
              <div className="contact-tips">
                您可以通过以上方式联系到我们，期待与您的合作！
              </div>
            </div>
          ) : null}
        </>
      )}
    </div>
  );
};

export default App;
