import axios from 'axios' //引入axios
import qs from 'qs';

const service = axios.create({
    // api 的 baseUrl 就是每个请求前面相同的地址，这个地方因为我配置了环境，所以直接取环境里的配置
    timeout: 5000, // 请求超时时间
})

// TODO: 修改post参数格式为Form表单格式
service.defaults.headers = {'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'};

// request拦截器
service.interceptors.request.use(
    config => {
        //这里是每个请求的设置，比如每个请求都携带一个token，或者当为post请求时，引入qs格式化参数。
        // const token = store.state.token;
        // token && (config.headers.Authorization = token);
        if (config.method !== 'get') {
            // form表单格式，需要序列化一下
            config.data = qs.stringify(config.data);
        }
        return config
    },
    error => {
        console.log('请求失败:' + error)
        Promise.reject(error)
    }
)

// response 拦截器
service.interceptors.response.use(
    response => {
        const res = response.data
        //这个地方的code自己与后台约定(可以根据状态码，设置自己要提示的信息)
        if (res.code !== 0) {
            //在此可以加几种比较普遍的设置，然后可以把单独的返到页面中设置
            return Promise.reject(res)
        } else {
            // return response.data
            return Promise.resolve(response.data.data);
        }
    },
    error => {
        console.log('响应失败:' + error)
        if (error && error.response.code) {
            switch (error.response.code) {
                // 401: 未登录
                // 未登录则跳转登录页面，并携带当前页面的路径
                // 在登录成功后返回当前页面，这一步需要在登录页操作。
                case 10054:
                    // 重新登录
                    // router.replace({
                    //     path: '/login',
                    //     query: { redirect: router.currentRoute.fullPath }
                    // });
                    break;
                // 403 token过期
                // 登录过期对用户进行提示
                // 清除本地token和清空vuex中token对象
                // 跳转登录页面
                case 403:
                    break;
                // 404请求不存在
                case 404:
                    break;
                // 其他错误，直接抛出错误提示
                default:
            }
        }
        return Promise.reject(error.response);
        // return Promise.reject(error)
    }
)
export default service
